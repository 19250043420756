import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ContactForm from '../components/ContactForm';
import Modal from '../components/Modal';

const Landing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  const parallaxEffect = (speed) => {
    return {
      y: scrollY * speed,
      transition: { type: 'spring', stiffness: 100 }
    };
  };

  return (
    <div className={`min-h-screen font-sans transition-colors duration-500 ${isDarkMode ? 'bg-coda-black text-coda-pearl' : 'bg-white text-gray-800'}`}>
      <AnimatePresence>
        <motion.button
          key="theme-toggle"
          onClick={toggleTheme}
          className={`fixed top-4 right-4 z-50 font-normal py-2 px-4 rounded-full transition-all duration-300 ${
            isDarkMode 
              ? 'bg-coda-pearl text-coda-black hover:bg-coda-cyan' 
              : 'bg-coda-black text-coda-pearl hover:bg-coda-blue'
          }`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {isDarkMode ? '☀️ Modo Claro' : '🌙 Modo Oscuro'}
        </motion.button>
      </AnimatePresence>

      <motion.section 
        className="relative h-screen flex items-center justify-center overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <motion.div
          className="absolute inset-0 z-0"
          initial={{ scale: 1.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 0.5 }}
          transition={{ duration: 2 }}
          style={parallaxEffect(0.2)}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-coda-blue to-coda-purple opacity-30"></div>
          <motion.div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url('/ai-background.jpg')` }}
            animate={{
              scale: [1, 1.05, 1],
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          ></motion.div>
        </motion.div>
        <div className="z-10 text-center">
          <motion.h1
            className="text-8xl font-light mb-6 tracking-tighter"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            CODA<span className="text-coda-cyan font-normal">.uno</span>
          </motion.h1>
          <motion.p
            className="text-4xl mb-8 max-w-3xl mx-auto font-extralight"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.7, duration: 1 }}
          >
            Reinventando la Realidad Digital
          </motion.p>
          <motion.p
            className="text-xl mb-12 max-w-2xl mx-auto font-light"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.9, duration: 1 }}
          >
            Donde la inteligencia artificial se encuentra con la creatividad humana para crear experiencias que desafían los límites de lo posible.
          </motion.p>
          <motion.button
            className="bg-coda-gradient from-coda-cyan to-coda-magenta text-coda-black font-normal py-4 px-8 rounded-full text-lg transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05, boxShadow: '0 0 20px rgba(0,255,255,0.5)' }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.1, duration: 1 }}
            onClick={openModal}
          >
            Descubre el Futuro
          </motion.button>
        </div>
      </motion.section>

      <main className="container mx-auto px-6">
        <motion.section 
          className="py-40"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <h2 className="text-6xl font-light mb-20 text-center">Sobre Nosotros</h2>
          <motion.p className="text-2xl mb-10 max-w-4xl mx-auto text-center leading-relaxed font-light"
            variants={fadeInUpVariants}
          >
            En el corazón de Argentina, lejos de Silicon Valley y los grandes centros tecnológicos, nace CODA: una historia de innovación contra todo pronóstico.
          </motion.p>
          <motion.p className="text-2xl mb-10 max-w-4xl mx-auto text-center leading-relaxed font-light"
            variants={fadeInUpVariants}
          >
            Somos un grupo de soñadores y hackers que creemos que la próxima revolución tecnológica no tiene por qué venir de los lugares esperados. Desde nuestro rincón en Sudamérica, estamos redefiniendo lo que significa ser una empresa de tecnología en el siglo XXI.
          </motion.p>
          <motion.p className="text-2xl mb-20 max-w-4xl mx-auto text-center leading-relaxed font-light"
            variants={fadeInUpVariants}
          >
            Nuestro secreto: combinamos el ingenio argentino, famoso por hacer maravillas con recursos limitados, con tecnología de punta en inteligencia artificial. El resultado es una mezcla única de creatividad, resiliencia y visión futurista.
          </motion.p>
          <motion.div className="text-center"
            variants={fadeInUpVariants}
          >
            <h3 className="text-4xl font-light mb-6">Nuestra misión</h3>
            <p className="text-2xl italic font-light">Democratizar la IA con un toque de genialidad nerd</p>
          </motion.div>
        </motion.section>

        <motion.section
          className="py-40"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <h2 className="text-6xl font-light mb-20 text-center">Nuestros Productos</h2>
          <div className="grid md:grid-cols-2 gap-24">
            <ProjectCard
              title="MateAI"
              description="Tu compañero digital que no solo entiende tu código, sino que también descifra tus pensamientos antes de que los tengas."
              features={[
                "Análisis de código avanzado",
                "Sugerencias de optimización",
                "Integración con IDEs populares"
              ]}
              link="https://mateai.uno"
              isDarkMode={isDarkMode}
            />
            <ProjectCard
              title="Musa"
              description="Una aplicación revolucionaria que te permite crear libros por capítulos sobre cualquier temática, con la asistencia de inteligencia artificial."
              features={[
                "Generación de ideas y tramas",
                "Asistente de escritura IA",
                "Organización por capítulos"
              ]}
              link="https://musa.coda.uno"
              isDarkMode={isDarkMode}
            />
          </div>
        </motion.section>

        <motion.section
          className="py-40"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <h2 className="text-6xl font-light mb-20 text-center">Por Qué Elegirnos</h2>
          <div className="grid md:grid-cols-3 gap-16">
            <FeatureCard 
              title="Eficiencia Inigualable" 
              description="Nuestro código es tan eficiente que hace que Moore's Law parezca una subestimación."
              isDarkMode={isDarkMode}
            />
            <FeatureCard 
              title="Seguridad Cuántica" 
              description="Ni Schrödinger podría decir si hemos sido hackeados o no."
              isDarkMode={isDarkMode}
            />
            <FeatureCard 
              title="Soporte 24/7" 
              description="Impulsado por cafeína y curiosidad infinita."
              isDarkMode={isDarkMode}
            />
          </div>
        </motion.section>

        <motion.section
          className="text-center py-40"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <h2 className="text-6xl font-light mb-16">¿Listo para llevar tu negocio al siguiente nivel cuántico?</h2>
          <p className="text-2xl mb-20 max-w-3xl mx-auto font-light">
            Únete a la revolución digital de CODA. Promesa: No convertiremos tu oficina en Skynet (a menos que lo solicites en JSON).
          </p>
          <motion.button 
            className="bg-coda-pearl text-coda-black font-normal py-6 px-12 rounded-full text-xl transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05, boxShadow: '0 0 20px rgba(240,234,214,0.3)' }}
            whileTap={{ scale: 0.95 }}
            onClick={openModal}
          >
            Inicia tu odisea IA
          </motion.button>
        </motion.section>

        <Modal isOpen={isModalOpen} closeModal={closeModal} title="Descubre el Futuro con CODA">
          <ContactForm />
        </Modal>
      </main>

      <footer className={`py-16 border-t ${isDarkMode ? 'border-coda-pearl border-opacity-20' : 'border-gray-200'}`}>
        <div className="container mx-auto px-6 text-center">
          <p className="text-2xl mb-6 font-light">Reinventando la realidad digital con inteligencia artificial y creatividad humana.</p>
          <p className="text-xl mb-10 font-light">© 2024 CODA.uno - Todos los derechos reservados.</p>
          <p className={`text-lg font-light ${isDarkMode ? 'opacity-60' : 'opacity-80'}`}>Haciendo que la IA sea tan cool que hasta los robots quieren ser nerds</p>
        </div>
      </footer>
    </div>
  );
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const FeatureCard = ({ title, description, isDarkMode }) => (
  <motion.div 
    className={`p-10 rounded-lg backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 ${
      isDarkMode 
        ? 'bg-gradient-to-br from-coda-blue/10 to-coda-purple/10' 
        : 'bg-gradient-to-br from-coda-blue/5 to-coda-purple/5'
    }`}
    whileHover={{ scale: 1.05 }}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={fadeInUpVariants}
  >
    <h3 className={`text-3xl font-light mb-6 ${
      isDarkMode
        ? 'bg-clip-text text-transparent bg-gradient-to-r from-coda-blue to-coda-purple'
        : 'text-coda-blue'
    }`}>{title}</h3>
    <p className={`text-xl font-light ${isDarkMode ? 'text-coda-pearl opacity-90' : 'text-gray-700'}`}>{description}</p>
  </motion.div>
);

const ProjectCard = ({ title, description, features, link, isDarkMode }) => (
  <motion.a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={`block p-10 rounded-lg backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300 ${
      isDarkMode 
        ? 'bg-gradient-to-br from-coda-magenta/10 to-coda-cyan/10' 
        : 'bg-gradient-to-br from-coda-magenta/5 to-coda-cyan/5'
    }`}
    whileHover={{ scale: 1.05 }}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={fadeInUpVariants}
  >
    <h3 className={`text-4xl font-light mb-6 ${
      isDarkMode
        ? 'bg-clip-text text-transparent bg-gradient-to-r from-coda-magenta to-coda-cyan'
        : 'text-coda-magenta'
    }`}>{title}</h3>
    <p className={`text-xl mb-6 font-light ${isDarkMode ? 'text-coda-pearl opacity-90' : 'text-gray-700'}`}>{description}</p>
    <ul className="list-disc list-inside mb-6">
      {features.map((feature, index) => (
        <li key={index} className={`text-lg font-light ${isDarkMode ? 'text-coda-pearl opacity-90' : 'text-gray-700'}`}>{feature}</li>
      ))}
    </ul>
    <span className={`text-xl font-light inline-block border-b-2 ${
      isDarkMode ? 'text-coda-pearl border-coda-cyan' : 'text-coda-cyan border-coda-cyan'
    }`}>Explorar {title} →</span>
  </motion.a>
);

export default Landing;