import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const ContactForm = ({ onSuccess }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setSubmitStatus('success');
        reset();
        if (onSuccess) onSuccess();
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitStatus('error');
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium mb-1 text-coda-pearl">Nombre</label>
        <input
          {...register('name', { required: 'Este campo es requerido' })}
          type="text"
          id="name"
          className="w-full p-2 bg-coda-black border border-coda-pearl rounded text-coda-pearl"
        />
        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium mb-1 text-coda-pearl">Email</label>
        <input
          {...register('email', { 
            required: 'Este campo es requerido',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Dirección de email inválida"
            }
          })}
          type="email"
          id="email"
          className="w-full p-2 bg-coda-black border border-coda-pearl rounded text-coda-pearl"
        />
        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
      </div>
      <div>
        <label htmlFor="message" className="block text-sm font-medium mb-1 text-coda-pearl">Mensaje</label>
        <textarea
          {...register('message', { required: 'Este campo es requerido' })}
          id="message"
          rows="4"
          className="w-full p-2 bg-coda-black border border-coda-pearl rounded text-coda-pearl"
        ></textarea>
        {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message.message}</p>}
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-coda-pearl text-coda-black font-bold py-2 px-4 rounded hover:bg-opacity-90 transition duration-300"
      >
        {isSubmitting ? 'Enviando...' : 'Enviar mensaje'}
      </button>
      {submitStatus === 'success' && (
        <p className="text-green-500 mt-2">Mensaje enviado con éxito.</p>
      )}
      {submitStatus === 'error' && (
        <p className="text-red-500 mt-2">Hubo un error al enviar el mensaje. Por favor, intenta de nuevo.</p>
      )}
    </form>
  );
};

export default ContactForm;